import { useParams } from 'react-router-dom';

import { EventContextEnum, WorkspaceEventsEnum } from '@heylog-app/shared/types';

import { useWsChannelEvent } from '../../../hooks';

import type {
	MessageResInterface,
	WorkspaceEventsType,
	EntityEventResType,
} from '@heylog-app/shared/types';
import type { KeyedMutator } from 'swr';

type Props = {
	updateMessages: KeyedMutator<MessageResInterface[]>;
	updateEvents: KeyedMutator<EntityEventResType[]>;
	updateMessagesV2: any;
};

const logRealtime = (event: WorkspaceEventsType) =>
	console.log(`[Realtime]: handling "${event}" event`);

export const useRealtimeConversationHook = ({ updateMessages, updateEvents, updateMessagesV2 }: Props) => {
	const { contactId = null } = useParams();

	useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_RECEIVED, () => {
		logRealtime(WorkspaceEventsEnum.MESSAGE_RECEIVED);
		updateMessages().then(() => {
			updateMessagesV2()
		}).catch((e) => console.error('error fetching new messages', e));
	});

	useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_SENT, () => {
		logRealtime(WorkspaceEventsEnum.MESSAGE_SENT);
		updateMessages().then(() => {
			updateMessagesV2()
		}).catch((e) => console.error('error fetching new messages', e));
	});

	useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_UPDATED, (payload) => {
		const { messageId, status, receivedAt, seenAt } = payload;

		logRealtime(WorkspaceEventsEnum.MESSAGE_UPDATED);
		updateMessages(
			(items) => {
				return items?.map((item) =>
					item.id === messageId ? { ...item, status, receivedAt, seenAt } : item,
				);
			},
			{ revalidate: true },
		).catch((e) => console.error('error updating message status', e));
	});

	useWsChannelEvent(WorkspaceEventsEnum.ENTITY_EVENT_ADDED, (payload) => {
		logRealtime(WorkspaceEventsEnum.ENTITY_EVENT_ADDED);

		// if contact matches to contact id we can do an in memory update
		if (
			payload.contextType === EventContextEnum.CONTACT_EVENT &&
			contactId === payload.externalID.toString()
		)
			// we don't need to sort here, events are always the newest
			updateEvents((events) => [...(events ?? []), payload], { revalidate: false }).catch(
				(e) => console.error('error updating events', e),
			);
		// otherwise we have no idea if the entity event is relevant to the current view - complete refresh
		else updateEvents().catch((e) => console.error('error updating events', e));
	});
};
